<template lang="pug">
div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" v-if="!isPending" )
  v-breadcrumbs(
    class="ml-0 pl-0"
    :items="breadcrumbs"
  )

  v-tabs.mb-8.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden(
    grow
    v-model="tab"
  )
    v-tab Advocacy

    v-tab-item.border-t
      .pa-6.rounded-b.shadow
        .flex.w-full
          v-btn(
            color="primary"
            small
            depressed
            @click="isAdvocacyCreateEditOpen = true"
            class="ml-auto"
          )
            span Update

        .mt-4.text-sm.font-medium.text-gray-500 Associated Org Types
        v-divider.mb-6

        .d-flex.align-top
          .d-flex.flex-column.flex-grow-1
            .flex-grow-1.mt-1.text-sm.text-gray-900(
              v-for="(selected, idx) of organizationTypes"
              :key="selected.id"
            )
              .inline-block - {{ selected.name }}


        .mt-4.text-sm.font-medium.text-gray-500 Issue Info
        v-divider.mb-6


        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 mb-6')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Name
            dd.mt-1.text-sm.text-gray-900 {{ advocacy.name }}

        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 mb-6')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Bill Number
            dd.mt-1.text-sm.text-gray-900 {{ advocacy.billNumber || '-' }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Budget
            dd.mt-1.text-sm.text-gray-900 {{ advocacy.formattedBudget }}

        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 mb-6')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Start
            dd.mt-1.text-sm.text-gray-900 {{ $day(advocacy.startDate).format(formats.DATE) }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 End
            dd.mt-1.text-sm.text-gray-900 {{ $day(advocacy.endDate).format(formats.DATE) }}

  AdvocacyCreateDialog(
    :isOpen="isAdvocacyCreateEditOpen"
    @close="closeAndRefresh"
    v-if="isAdvocacyCreateEditOpen"
    :viewModel="{ context: 'edit', id }"
  )

  v-data-table.border.shadow(
    must-sort
    :items="expenses"
    :loading="expensesPending"
    :headers="headers"
    :server-items-length="!expensesPending && pagination ? pagination.total : 0"
    :options.sync="options"
    no-data-text="No approved expenses found"
  )
    template(
      v-slot:item.invoiceDate="{ item: expense }"
    )
      | {{ $day(expense.invoiceDate).format(formats.DATE_SHORT) }}
    template(
      v-slot:item.amount="{ item }"
    )
      h6 {{ item.formattedMoney }}

    template(
      v-slot:item.status="{ item: expense }"
    )
      ExpenseChip( :expense="expense" )
    template( v-slot:body.append )
      tr.bg-gray-100( v-if="!expensesPending" )
        td( width="50%" )
          span Sum
        td
        td( :colspan="2" ).text-end
          span.font-bold {{ $sumFmt }}
          span &nbsp; / {{ advocacy.budget | toCurrency(0) }}
          div( style="line-height: 9px; color: #AAA; font-size: 11px; text-align: right" ) BUDGET
</template>

<script>
import { useGet } from 'feathers-vuex'
import { formats } from '@/helpers/data'
import useDataTableFind from '@/helpers/useDataTableFind'
import { computed, ref } from '@vue/composition-api'
import AdvocacyCreateDialog from './Advocacy.CreateEdit.Dialog.vue'
import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'
import ExpenseChip from './../expenses/Expense.Chip';

export default {
  name: 'AdvocacyView',

  components: {
    AdvocacyCreateDialog,
    OrgTypeSelect,
    ExpenseChip
  },

  setup(props, { root: { $route, $FeathersVuex, $snackSuccess } }) {
    const { Advocacy, AdvocacyOrganizationType } = $FeathersVuex.api;
    const id = parseInt($route.params.id)
    const tab = ref('advocacies')
    const isAdvocacyCreateEditOpen = ref(false)
    const ADVOCACY_ORG_PARAMS = {
      query: { $eager: 'orgTypes' }
    }

    const closeAndRefresh = () => {
      get(id, ADVOCACY_ORG_PARAMS)
      isAdvocacyCreateEditOpen.value = false
    }

    const { item: advocacy, isPending, get } = useGet({
      model: Advocacy,
      id,
      params: ADVOCACY_ORG_PARAMS
    });

    const organizationTypes = computed({
      get() {
        if (!isPending.value) {
          return advocacy.value.orgTypes;
        }
      },
      set (newItems) {
        selectedOrgTypes.value = newItems
      }
    })
    const selectedOrgTypes = ref(organizationTypes.value)

    const breadcrumbs = computed(() => {
      return [{
        text: 'Advocacies',
        link: true,
        disabled: false,
        exact: true,
        to: '/advocacies'
      },
      {
        text: advocacy.value.name,
        disabled: true
      }]
    })

    const updateOrgIds = async (ids) => {
      try {
        const advocacyId = parseInt(id)
        const candidateOrgTypes = new AdvocacyOrganizationType({
          advocacyId,
          organizationTypeId: ids
        })
        await candidateOrgTypes.create()

        $snackSuccess('Org types updated')
      } catch (e) {
        console.error(e)
      }
    }

    const headers = [
      {
        text: 'Status',
        value: 'status',
        width: '150'
      },
      {
        text: 'Date',
        align: 'end',
        value: 'invoiceDate'
      },
      {
        text: 'Amount',
        value: 'amount',
        align: 'end',
        sortable: true
      }
    ]

    const filter = computed(() => {
      const filter = {
        $eager: '[vendor, advocacy]',
        advocacyId: id,
        status: {
          $in: ['submitted', 'approved', 'completed']
        },
        $sum: [
          'amount',
          'sum',
          { advocacyId: id, status: ['submitted', 'approved', 'completed'] }
        ]
      }
      return filter
    })

    const {
      items: expenses,
      isPending: expensesPending,
      pagination,
      sum,
      options,
      result
    } = useDataTableFind(
      'expenses',
      headers,
      filter,
      { sortBy: ['invoiceDate'] }
    )
    const $sumFmt = computed(() => result.value ? result.value.$sum.fmt : 0)

    return {
      expenses,
      expensesPending,
      pagination,
      headers,
      sum,
      options,
      $sumFmt,

      advocacy,
      isPending,

      closeAndRefresh,
      updateOrgIds,
      organizationTypes,

      isAdvocacyCreateEditOpen,
      breadcrumbs,
      formats,
      tab,
      id,
      selectedOrgTypes
    }
  }
}
</script>